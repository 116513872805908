import React from 'react'
import InputRadio from '../inputs/InputRadio'

const LeyendaConInputRadio = ({
  seleccionado,
  funcion,
  titulo,
  estilosTexto = 'text-cendiatra-verde-2 ',
}) => {
  return (
    <div
      className={`w-full flex justify-center items-center my-2.5px ${estilosTexto}`}
    >
      <div className="w-7/12 flex justify-start items-center">
        <span className="text-13px">{titulo}</span>
      </div>
      <div className="w-2/12 flex justify-center items-center">
        <InputRadio
          estilos={seleccionado ? 'pointer-events-none' : ''}
          seleccionado={seleccionado}
          funcion={funcion}
        />
      </div>
    </div>
  )
}

export default LeyendaConInputRadio
