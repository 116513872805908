import React, {useContext, useEffect, useState} from 'react'
import {Route, Redirect} from 'react-router-dom'
import Landing from './paginas/Landing'
import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import BarraDeNavegacion from './componentes/Navegacion/BarraDeNavegacion'
import {BrowserRouter} from 'react-router-dom'
import Router from './Router'
import {useMsal} from '@azure/msal-react'
import GrupoImagenes from './componentes/GrupoImagenes'
import ModalDinamico from './componentes/Modales/ModalDinamico'
import {ContextoAplicacion} from './contexto/ContextoAplicacion'
import Spinner from './componentes/Spinner'
import Dexie from 'dexie'
import {obtenerVersionApp} from './config'
import {RUTAS_APLICACION} from './constantes'
import AxiosInterceptador from './interceptador/AxiosInterceptador'
import {GrowthBook} from '@growthbook/growthbook-react'
import {GrowthBookProvider} from '@growthbook/growthbook-react'
import {obtenerFeatureFlagConfig} from './config.js'

// import { InteractionType } from '@azure/msal-browser';

const App = () => {
  // useMsalAuthentication(InteractionType.Redirect)

  const {instance} = useMsal()
  const [numeroVersion, setNumeroVersion] = useState('Cargando versión...')

  const contextoAplicacion = useContext(ContextoAplicacion)
  const [growthbook, setGrowthbook] = useState(null)

  useEffect(() => {
    obtenerFeatureFlagConfig()
      .then((config) => {
        const devMode =
          config.DevMode === 'true' || config.DevMode == true ? true : false
        const gb = new GrowthBook({
          apiHost: config.Host,
          clientKey: config.Key,
          enableDevMode: devMode,
          trackingCallback: (experiment, result) => {
            console.log('Viewed Experiment', {
              experimentId: experiment.key,
              variationId: result.key,
            })
          },
        })

        gb.init({streaming: true})
        setGrowthbook(gb)
      })
      .catch((error) => {
        console.error('Error iniciando Growth:', error)
      })
  }, [])

  useEffect(() => {
    obtenerVersionApp()
      .then((version) => {
        setNumeroVersion(`4.1.${version}`)
      })
      .catch((err) => {
        console.error('Error obteniendo versión: ', err)
      })
  }, [])

  const cerrarSesion = () => {
    sessionStorage.clear()
    instance.logoutRedirect({postLogoutRedirectUri: '/'})
    Dexie.delete('CendiatraClienteCacheComercial')
    Dexie.delete('CendiatraClienteCacheComun')
  }

  return (
    <>
      <GrowthBookProvider growthbook={growthbook}>
        <AuthenticatedTemplate>
          <AxiosInterceptador>
            <ModalDinamico />
            {contextoAplicacion.cargando ? <Spinner /> : null}
            <BrowserRouter>
              <div className=" w-full min-h-screen sticky top-0 z-50">
                <BarraDeNavegacion cerrarSesion={cerrarSesion} />

                <Router />
                <Route exact path={RUTAS_APLICACION.MAIN}>
                  <Redirect to={RUTAS_APLICACION.HOME} />
                </Route>
                <div className="text-cendiatra fixed text-sm bottom-0 right-0 p-2">
                  {' '}
                  {numeroVersion}
                </div>
              </div>
            </BrowserRouter>
          </AxiosInterceptador>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <BrowserRouter>
            <Route exact path={RUTAS_APLICACION.HOME}>
              <Redirect to={RUTAS_APLICACION.MAIN} />
            </Route>
          </BrowserRouter>
          <Landing />
        </UnauthenticatedTemplate>
      </GrowthBookProvider>
    </>
  )
}

export default App
